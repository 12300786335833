
<div class="p-6 space-y-4">
<!--  <pre>-->
<!--    {{datos |json}}-->
<!--  </pre>-->
  <form [formGroup]="formularioDatos" (ngSubmit)="onSubmit()" class="space-y-4">
        @for (controlName of  datosSeleccionados; track controlName) {
<!--          {{controlName | json}}-->
          <label [for]="controlName" class="block text-sm font-bold text-gray-700">{{ controlName.etiqueta }}</label>
          @if (controlName.tipo == 'text') {
            <input [id]="controlName.clave" [formControlName]="controlName.clave" type="text" class="mt-1 block w-full border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
          } @else if (controlName.tipo == 'date') {
            <input [id]="controlName.clave" [formControlName]="controlName.clave" type="date" class="mt-1 block w-full border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
          }
        }

    <button type="submit" class="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Guardar</button>
  </form>
</div>

