import { Component } from '@angular/core';
import {FormularioCargaComponent} from "../formulario-carga/formulario-carga.component";
import {FormularioDatosComponent} from "../formulario-datos/formulario-datos.component";

@Component({
  selector: 'app-formulario-contenedor',
  standalone: true,
  imports: [
    FormularioCargaComponent,
    FormularioDatosComponent
  ],
  templateUrl: './formulario-contenedor.component.html',
  styleUrl: './formulario-contenedor.component.css'
})
export class FormularioContenedorComponent {
  datosRecibidos: any = null;
  tipoArchivoSeleccionado: any = null;

  manejarArchivoSubido(event: any) {
    // Procesa el evento para extraer los datos recibidos y guárdalos en `datosRecibidos`
    this.datosRecibidos = event;
  }

  manejarArchivoSeleccionado(event: any) {
    this.tipoArchivoSeleccionado = event
  }
}
