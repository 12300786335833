import {Component, EventEmitter, inject, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {UploadFileService} from "../services/upload-file.service";
import Swal from 'sweetalert2';
import {DocumentosAPIService} from "../services/documentos-api.service";
import { switchMap } from 'rxjs/operators';
import {environment} from "../../environments/environment";


@Component({
  selector: 'app-formulario-carga',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './formulario-carga.component.html',
  styleUrl: './formulario-carga.component.css'
})
export class FormularioCargaComponent {
  @Output() archivoSubido = new EventEmitter<any>();
  @Output() tipoArchivoSeleccionado = new EventEmitter<any>();
  uploadService = inject(UploadFileService)
  documentosAPIService = inject(DocumentosAPIService)


  formularioCarga = new FormGroup({
    tipoArchivo: new FormControl('', Validators.required), // Asegúrate de tener validaciones si son necesarias
    archivo: new FormControl(false, Validators.required) // Usa un booleano como placeholder
  });
  camposDinamicos: any[] = [];


  opcionesTipoArchivo = [
    { id: "NOTA_INFORMATIVA", nombre: 'Nota Informativa'}, // Especifica aquí los campos para cada tipo
    // { id: 2, nombre: 'Tipo 2', campos: [] },
    // Agrega más opciones según sea necesario
  ];

  archivoSeleccionado: File | null = null;

  cargarArchivo(event: Event): void {
    const elemento = event.target as HTMLInputElement;
    // console.log(elemento.files)

    if (elemento.files && elemento.files.length > 0) {
      this.archivoSeleccionado = elemento.files[0];
      this.formularioCarga.patchValue({ archivo: true });
      // Si también deseas validar el archivo (p.ej., por tamaño o tipo), este es un buen lugar para hacerlo.
    } else {
      this.formularioCarga.patchValue({ archivo: false });
      this.archivoSeleccionado = null;
    }

  }


  onSubmit(): void {
    if (this.formularioCarga.valid && this.archivoSeleccionado) {
      // Aquí puedes implementar la lógica para manejar la carga del archivo
      console.log('Subida de archivo');
      // console.log(this.archivoSeleccionado);
      let file = this.archivoSeleccionado
      let key = `uploads/${file.name}`
      Swal.showLoading();

      // Subir archivo y luego obtener la nota informativa
      this.uploadService.uploadFile(file, environment.awsConfig.bucket, key).pipe(
        switchMap(data => {
          // Muestra el mensaje de que el archivo se ha subido correctamente
          Swal.close();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Subida de archivo",
            text: "El archivo ha subido correctamente",
            showConfirmButton: false,
          });
          // Inicia el loading aquí
          Swal.showLoading();
          // Llama a la siguiente API pasando la key
          return this.documentosAPIService.getNotaInformativa(data, environment.awsConfig.bucket);
        })
      ).subscribe({
        next: (res) => {
          // Detiene el loading aquí
          Swal.close();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Análisis de archivo",
            text: "El archivo ha sido analizado correctamente",
            showConfirmButton: false,
            timer: 1500
          });
          console.log('Respuesta del análisis:', res);
          this.archivoSubido.emit(res);
          this.tipoArchivoSeleccionado.emit(this.formularioCarga.get('tipoArchivo')?.value)
        },
        error: (error) => {
          // Detiene el loading aquí también en caso de error
          Swal.close();
          console.error('Error en el proceso:', error);
          Swal.fire({
            icon: "error",
            title: "Error en el proceso",
            text: "Ha ocurrido un error",
            showConfirmButton: false,
          });
        }
      });
    }
  }
}
