export const environment = {
  production: false,
  apiUrl: 'https://jgxuuq2h6juxmcbdh4puea6lt40grqkn.lambda-url.eu-west-1.on.aws',
  awsConfig: {
    region: 'eu-west-1',
    accessKeyId: 'AKIAVJQTAL4PINIDIGHH',
    secretAccessKey: '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',
    bucket: 'documentossedes'
  }
};
