<div class="grid grid-cols-2 h-screen">
  <div class="overflow-auto">
    <!-- Aquí insertarás el formulario de carga -->
    <app-formulario-carga (archivoSubido)="manejarArchivoSubido($event)"  (tipoArchivoSeleccionado)="manejarArchivoSeleccionado($event)"></app-formulario-carga>
  </div>
  <div class="overflow-auto">
    <!-- El segundo formulario se mostrará aquí, basado en la condición -->
    @if (datosRecibidos && tipoArchivoSeleccionado) {
      <app-formulario-datos [datos]="datosRecibidos" [tipoArchivoSeleccionado]="tipoArchivoSeleccionado"></app-formulario-datos>
    }
  </div>
</div>
