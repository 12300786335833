import { Injectable } from '@angular/core';
import * as AWS from "@aws-sdk/client-s3";
import {environment} from "../../environments/environment";
import {catchError, from, map, Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UploadFileService {
  // private s3: S3;
  client = new AWS.S3({ region: environment.awsConfig.region, credentials: {
      accessKeyId: environment.awsConfig.accessKeyId,
      secretAccessKey: environment.awsConfig.secretAccessKey
    } });
  constructor() {


    // this.s3 = new S3({
    //   region: environment.awsConfig.region,
    //   credentials: {
    //     accessKeyId: environment.awsConfig.accessKeyId,
    //     secretAccessKey: environment.awsConfig.secretAccessKey
    //   }
    // });
  }



  uploadFile(file: File, bucketName: string, key: string): Observable<string>{
    const putObjectCommand = new AWS.PutObjectCommand({
      Bucket: bucketName,
      Key: key,
      Body: file,
    });

    // `from` convierte la promesa retornada por `client.send()` en un Observable
    return from(this.client.send(putObjectCommand)).pipe(
      // `map` para transformar la respuesta a la key, pero como `send` no retorna datos útiles aparte del hecho de que fue exitoso, simplemente retornamos la key directamente.
      map(() => key),
      // Aquí puedes agregar más operadores si necesitas transformar los datos, manejar errores, etc.
      catchError(error => {
        console.error("Error al subir el archivo:", error);
        return throwError(() => new Error("Error al subir el archivo"));
      })
    );
  }
}
