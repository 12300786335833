<div class="min-h-screen bg-gray-100 flex items-center justify-center">
  <div class="max-w-md w-full bg-white shadow-md rounded-lg p-6 space-y-4">
    <form [formGroup]="formularioCarga" (ngSubmit)="onSubmit()" class="space-y-4">
      <div>
        <label for="tipoArchivo" class="block text-sm font-medium text-gray-700">Tipo de Archivo</label>
        <select id="tipoArchivo" formControlName="tipoArchivo" class="mt-1 block w-full border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
          @for  (opciones of opcionesTipoArchivo; track opciones.id) {
          <option [value]="opciones.id">{{opciones.nombre}}</option>
        }
        </select>
      </div>

      <div>
        <label for="archivo" class="block text-sm font-medium text-gray-700">Archivo</label>
        <input type="file" id="archivo" (change)="cargarArchivo($event)" class="mt-1 block w-full border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
      </div>

      <button type="submit" [disabled]="!formularioCarga.valid || !archivoSeleccionado"  class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
        Subir
      </button>

      <!-- Aquí se renderizarían los campos dinámicos basados en la selección -->
    </form>
  </div>
</div>

