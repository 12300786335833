import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {JsonPipe} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {tsDeclareVariable} from "@angular/compiler-cli/src/ngtsc/typecheck/src/ts_util";
import {NotasInformativasResponse} from "../interfaces/documentos/notas-informativas-response";
import Swal from "sweetalert2";
@Component({
  selector: 'app-formulario-datos',
  standalone: true,
  imports: [
    JsonPipe,
    ReactiveFormsModule
  ],
  templateUrl: './formulario-datos.component.html',
  styleUrl: './formulario-datos.component.css'
})
export class FormularioDatosComponent implements OnChanges {
  @Input() datos: any;
  @Input() tipoArchivoSeleccionado: any;
  // @Input() tipoArchivo: any;
  formularioDatos: FormGroup;
  datosSeleccionados: any;

  estructuraCampos = {
    "NOTA_INFORMATIVA": [ // Asume que '1' es el id de la selección
      { clave: 'renting', etiqueta: 'Renting', tipo: 'text'  },
      { clave: 'cargas', etiqueta: 'Cargas', tipo: 'text'},
      { clave: 'fecha_matriculacion', etiqueta: 'Fecha de Matriculación', tipo: 'date' },
      { clave: 'fecha_1_matriculacion', etiqueta: 'Fecha de Primera Matriculación', tipo: 'date' },
      { clave: 'filiacion', etiqueta: 'filiacion', tipo: 'text'},
      { clave: 'seguro', etiqueta: 'seguro', tipo: 'text'},
      { clave: 'aseguradora', etiqueta: 'aseguradora', tipo: 'text'},
      { clave: 'reserva', etiqueta: 'Reserva', tipo: 'text'},
      { clave: 'matricula', etiqueta: 'Matricula', tipo: 'text'},
      { clave: 'marca', etiqueta: 'Marca', tipo: 'text'},
      { clave: 'modelo', etiqueta: 'Modelo', tipo: 'text'},
      { clave: 'bastidor', etiqueta: 'Bastidor', tipo: 'text'},
      { clave: 'servicio', etiqueta: 'Servicio', tipo: 'text'},
      { clave: 'cilindrada', etiqueta: 'cilindrada', tipo: 'text'},
      { clave: 'combustible', etiqueta: 'combustible', tipo: 'text'},
      { clave: 'potencia_neta_kw', etiqueta: 'potencia_neta_kw', tipo: 'text'},
      { clave: 'potencia_fiscal_cvf', etiqueta: 'potencia_fiscal_cvf', tipo: 'text'},
      { clave: 'relacion_potencia_peso', etiqueta: 'relacion_potencia_peso', tipo: 'text'},
      { clave: 'plazas', etiqueta: 'plazas', tipo: 'text'},
      { clave: 'plazas_pie', etiqueta: 'plazas_pie', tipo: 'text'},
      { clave: 'masa_maxima', etiqueta: 'masa_maxima', tipo: 'text'},
      { clave: 'tara_kg', etiqueta: 'tara_kg', tipo: 'text'},
      { clave: 'masa_maxima_en_carga', etiqueta: 'masa_maxima_en_carga', tipo: 'text'},
      { clave: 'masa_en_circulacion', etiqueta: 'masa_en_circulacion', tipo: 'text'},
      { clave: 'altura', etiqueta: 'altura', tipo: 'text'},
      { clave: 'clas_industria', etiqueta: 'clas_industria', tipo: 'text'},
      { clave: 'tipo', etiqueta: 'tipo', tipo: 'text'},
      { clave: 'variante', etiqueta: 'variante', tipo: 'text'},
      { clave: 'version', etiqueta: 'version', tipo: 'text'},
      { clave: 'contr_hom', etiqueta: 'contr_hom', tipo: 'text'},
      { clave: 'via_anterior', etiqueta: 'via_anterior', tipo: 'text'},
      { clave: 'via_posterior', etiqueta: 'via_posterior', tipo: 'text'},
      { clave: 'dist_entre_ejes', etiqueta: 'dist_entre_ejes', tipo: 'text'},
      { clave: 'emisiones_co2', etiqueta: 'emisiones_co2', tipo: 'text'},
      { clave: 'nivel_de_emisiones', etiqueta: 'nivel_de_emisiones', tipo: 'text'},
      { clave: 'longitud', etiqueta: 'longitud', tipo: 'text'},
      { clave: 'anchura', etiqueta: 'anchura', tipo: 'text'},
      // Agrega más campos según sea necesario
    ],
    2: [ // Ejemplo para otro id
      // Define otros campos específicos para esta selección
    ]
    // Añade más estructuras para otros ids según sea necesario
  };


  constructor(private fb: FormBuilder) {
    this.formularioDatos = this.fb.group({});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['datos']) {
      // console.log(this.tipoArchivo)

      this.crearFormularioPorSeleccion(this.tipoArchivoSeleccionado);

    }
  }

  crearFormularioPorSeleccion(idSeleccion: string): void {
    // console.log(this.datos)
    // console.log(idSeleccion)
    if (!this.datos) {
      // Si datos es undefined o null, salir de la función.
      console.log("Datos no está definido aún.");

      return;
    }


    // @ts-ignore
    const camposSeleccionados = this.estructuraCampos[idSeleccion] || [];
    const grupo = {};

    camposSeleccionados.forEach((campo: any) => {

      let valorInicial;

      // Verifica si el campo debería venir de `informacion_tecnica`
      if (this.datos.informacion_tecnica && this.datos.informacion_tecnica.hasOwnProperty(campo.clave)) {
        valorInicial = this.datos.informacion_tecnica[campo.clave] ?? '';
      } else {
        // Para propiedades no anidadas o fuera de `informacion_tecnica`
        valorInicial = this.datos[campo.clave] ?? '';
      }

      // Conversión de fechas, si es aplicable
      if (campo.tipo === 'date' && valorInicial) {
        const partesFecha = valorInicial.split('-');
        valorInicial = `${partesFecha[2]}-${partesFecha[1]}-${partesFecha[0]}`; // Convierte a 'YYYY-MM-DD'
      }

      // @ts-ignore
      grupo[campo.clave] = [valorInicial];

    });

    this.formularioDatos = this.fb.group(grupo);
    this.datosSeleccionados = camposSeleccionados;

  }

  onSubmit(): void {
    // Guaradar los datos
    // console.log('guardar los datos')
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Información guardad",
      text: "La información ha sido guardada correctamente",
      showConfirmButton: false,
      timer: 1500
    });
    window.location.reload()
  }


  protected readonly Object = Object;
}
