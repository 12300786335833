import { Routes } from '@angular/router';
import {FormularioContenedorComponent} from "./formulario-contenedor/formulario-contenedor.component";

export const routes: Routes = [

  {
    path: 'informes',
    loadComponent: () => FormularioContenedorComponent,
  },
  {
    path: '',
    redirectTo: '/informes',
    pathMatch: 'full'
  }

];
