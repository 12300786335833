import {inject, Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import { catchError, Observable, throwError, of } from 'rxjs';
import { delay } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class DocumentosAPIService {
  private readonly baseUrl: string = environment.apiUrl
  private http = inject(HttpClient);
  constructor() { }


  getNotaInformativa(key: string, bucket: string) {
    const url = `${this.baseUrl}/api/v1/ocr/informe`
    let params = new HttpParams().set('documento', 'NOTA_INFORMATIVA').set('key', key).set('bucket', bucket);
    const body = {}
    return this.http.post<any>(url, body, { params }).pipe(
       catchError(error => {
         // Aquí puedes manejar el error, por ejemplo, registrándolo o transformándolo
         console.error('Error al obtener la nota informativa:', error);
         return throwError(() => new Error('Error al obtener la nota informativa'));
       })
     );
  }
}
